<template>
  <div class="dialogcomp" v-if="dialogshow" ref="dialogwrap">
    <!--蒙层-->
    <transition name="el-fade-in">
      <div class="shadow" v-show="visible"></div>
    </transition>
    <!--弹窗-->
    <transition name="el-fade-in">
      <div class="dialogcontent">
        <div class="dialog" v-show="visible" :style="{width,marginTop:margintop+'px',marginBottom:marginbottom+'px'}" ref="dialogcontent">
          <p class="titlebar">
            <span>{{owntitle||title}}</span>
            <img src="./close.png" @click="handlecancel" />
          </p>
          <div class="content">
            <div class="contentslot">
              <slot>
                <p class="msg">{{msg}}</p>
              </slot>
            </div>
            <div class="btns" v-if="!hidebtns">
              <button class="printbtn" @click="handleprint" v-if="print">打印</button>
              <button class="confirmbtn" @click="handlesubmit">{{confirmtxt||'确认'}}</button>
              <button class="cancelbtn" @click="handlecancel">{{canceltxt||'取消'}}</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'clo-dialog',
  props: {
    // 弹窗
    title: {
      type: String,
      default: '标题',
    }, // 标题
    width: {
      type: String,
      default: '378px',
    }, // 弹窗宽度
    hidebyselg: {
      type: Boolean,
      default: true,
    }, // 是否手动关闭弹窗
    confirmtxt: String, // 确认按钮文案
    canceltxt: String, // 取消按钮文案
    print: Boolean, // 是否需要打印
    hidebtns: {
      type: Boolean,
      default: false,
    }, // 是否隐藏按钮
  },
  data() {
    return {
      visible: false, // 是否显示弹窗
      dialogshow: false, // 是否显示弹窗
      msg: '', // 直接消息内容
      owntitle: '', // 自定义标题内容
      callback: null, // 主动回调
      cancelcallback: null, // 取消回调
      margintop: 100, // 默认边距大小
      marginbottom: 0, // 默认边距大小
    };
  },
  methods: {
    /**
     * 主动隐藏
     */
    hide() {
      this.visible = false;
      setTimeout(() => {
        this.dialogshow = false;
      }, 300);
    },
    /**
     * 显示方法
     */
    show(title, msg, callback, cancelcallback) {
      this.callback = callback;
      this.cancelcallback = cancelcallback;
      this.owntitle = title;
      this.msg = msg;
      this.dialogshow = true;
      this.visible = true;
      this.$emit('show');
      setTimeout(() => {
        this.fitwindow();
      }, 500);
    },
    /**
     * 提交回调
     */
    handlesubmit() {
      if (this.callback) {
        this.callback();
      }
      this.$emit('submit');
      if (!this.hidebyselg) { this.hide(); }
    },
    /**
     * 取消回调
     */
    handlecancel() {
      this.$emit('cancel');
      // this.cancelcallback && this.cancelcallback();
      this.cancelcallback();
      this.hide();
    },
    /**
     * 打印按钮回调
     */
    handleprint() {
      this.$emit('print');
    },
    /**
     * 适配窗口位置和大小
     */
    fitwindow() {
      const amountheight = this.$refs.dialogwrap.offsetHeight;
      const contentheight = this.$refs.dialogcontent.offsetHeight;
      const mg = amountheight - contentheight;
      if (mg > 0) {
        this.margintop = mg / 2;
      } else {
        this.margintop = 10;
        this.marginbottom = 10;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/mixin.scss";
.dialogcomp {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .dialogcontent {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    z-index: 1;
    .dialog {
      transition: all 300ms ease;
      min-width: 378px;
      margin-left: 50%;
      transform: translateX(-50%);
      .titlebar {
        margin-bottom: 0;
        height: 45px;
        background-color: #2064ff;
        border-top-left-radius: 11px;
        border-top-right-radius: 11px;
        padding-left: 28px;
        padding-right: 14px;
        @include flex-row(space-between);
        span {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
        }
        img {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }
      .content {
        border-bottom-left-radius: 11px;
        border-bottom-right-radius: 11px;
        background-color: white;
        min-height: 210px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .msg {
          text-align: center;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 26px;
          padding-top: 66px;
        }
      }
      .btns {
        @include flex-row(center);
        padding-bottom: 30px;
        button {
          outline: none;
          border: none;
          transition: all 300ms ease;
          box-sizing: border-box;
        }
        .confirmbtn {
          margin-right: 26px;
          width: 82px;
          height: 29px;
          background: rgba(32, 100, 255, 0);
          border: 1px solid rgba(32, 100, 255, 1);
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(32, 100, 255, 1);
          line-height: 29px;
        }
        .cancelbtn {
          width: 82px;
          height: 29px;
          background: rgba(32, 100, 255, 1);
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 29px;
        }
        .printbtn{
          margin-right: 26px;
          width: 82px;
          height: 29px;
          background: #18B508;
          border: 1px solid #18B508;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: white;
          line-height: 29px;
        }
      }
    }
  }
}
</style>
