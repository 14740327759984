/**
 * 图片url处理成缩略图
 * url末尾加 _S
 */
const thumbnail = {
  inserted(el) {
    el.onload = () => {
      let { src } = el;
      const arr = src.split('.');
      if (!arr[arr.length - 2].includes('_S')) {
        arr[arr.length - 2] += '_S';
      }
      src = arr.join('.');
      el.src = src;
    };
  },
};

export default thumbnail;
