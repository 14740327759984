/**
 * 各类验证
 */
import common from '@/const/common';

const validate = {
  // isURL(s) {
  //   return /^http[s]?:\/\/.*/.test(s);
  // },
  /**
   * 空判断
   */
  validateNull(val) {
    if (val === null || val === undefined) {
      return true;
    }
    if (typeof val === 'string') {
      if (val === 'null' || val === 'undefined' || val === '') {
        return true;
      }
    }
    if (val instanceof Array) {
      if (val.length === 0) return true;
    } else if (val instanceof Object) {
      if (JSON.stringify(val) === '{}') return true;
    }
    return false;
  },
  /**
   * 手机号验证
   */
  validatePhoneRegular(num) {
    const re = common.phoneRegular;
    return re.test(num);
  },
  /*
   * 金额验证
   */
  validateMoneyRegular(num) {
    const re = common.moneyRegular;
    return re.test(num);
  },
  /**
   * 判断对象上是否存在某属性
   */
  hasOwnProperty(obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  },
};
export default validate;
