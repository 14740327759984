/**
 * 防抖 v-debounce
 * 可传入防抖时间：time
 */
const debounce = {
  inserted(el, binding) {
    const debounceTime = binding.arg.time || 1000;
    let timer;
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        binding.value();
      }, debounceTime);
    });
  },
};

export default debounce;
