/**
 * 日期相关
 *
 * 1.格式化年月日(兼容ios)
 *   formatDate(data,type) {}
 * 2.毫秒转秒
 *   millisecondToSecond() {}
 */
const filterDate = {
  /**
  * 格式化年月日(兼容ios)
  */
  formatDate(time, type) {
    let date = time;
    if (date === null) {
      return '';
    }
    if (typeof date !== 'number' && typeof date !== 'object') {
      return '';
    }
    if (typeof date === 'number') {
      date = new Date(date * 1000);
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if (type === 'hour') {
      const hour = date.getHours();
      return `${[year, month, day].map(this.formatNumber).join('-')} ${[hour].map(this.formatNumber).join(':')}`;
    }
    if (type === 'minute') {
      const hour = date.getHours();
      const minute = date.getMinutes();
      return `${[year, month, day].map(this.formatNumber).join('-')} ${[hour, minute].map(this.formatNumber).join(':')}`;
    }
    if (type === 'second') {
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      return `${[year, month, day].map(this.formatNumber).join('-')} ${[hour, minute, second].map(this.formatNumber).join(':')}`;
    }

    return [year, month, day].map(this.formatNumber).join('-');
  },

  /**
  * 判断时间位数，1位自动补0
  */
  formatNumber(number) {
    const n = number.toString();
    return n[1] ? n : `0${n}`;
  },

  /**
   * 毫秒转秒
   */
  millisecondToSecond(millisecond) {
    if (millisecond) {
      return Math.round(millisecond / 1000);
    }
    return '';
  },
};
export default filterDate;
