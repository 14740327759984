/**
 * 截流 v-throttle
 * 可传入截流时间：time
 */
const throttle = {
  bind(el, binding) {
    const time = binding.arg.time || 1000;
    let preTime = new Date().getTime();
    el.addEventListener('click', (e) => {
      const nowTime = new Date().getTime();
      if (preTime && nowTime - preTime < time) {
        e.preventDefault();
        e.stopImmediatePropagation();
      } else {
        preTime = nowTime;
        binding.value();
      }
    });
  },
};

export default throttle;
