import page from './page';
import common from './common';
import viewsStatus from './views/status';
import viewsType from './views/type';

const constant = {
  ...page,
  ...common,
  ...viewsStatus,
  ...viewsType,
};
export default constant;
