import Vue from 'vue';

const requireComponent = require.context('./base-components', true, /\.js$/);
// 遍历获取到的文件名，依次进行全局注册
requireComponent.keys().forEach((component) => {
  const componentConfig = requireComponent(component);
  const compatible = componentConfig.default || componentConfig;
  Vue.component(compatible.name, compatible);
});
// const requireBusinessComponent = require.context('./business-components', true, /\.js$/);
// // 遍历获取到的文件名，依次进行全局注册
// requireBusinessComponent.keys().forEach((component) => {
//   const componentConfig = requireBusinessComponent(component);
//   const compatible = componentConfig.default || componentConfig;
//   Vue.component(compatible.name, compatible);
// });
