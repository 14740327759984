/**
 * 格式化相关
 */
const filterFormat = {
  /**
   * 数值格式化
   * @param {*} num
   * @param {Number} unit 精确的位数
   */
  numberFormat(num, unit) {
    return Math.round(parseFloat(num) * unit) / unit;
  },
};
export default filterFormat;
