/**
 * 图片错误时加载默认图 v-imageError
 */
import defaultImg from '@/assets/default-img.png';

const imageError = {
  inserted(el) {
    el.onerror = () => {
      el.src = defaultImg;
      el.onerror = null;
    };
  },
};

export default imageError;
