<script>
// eslint-disable-next-line import/no-cycle
import Util from '@/util/util';

export default {
  mounted() {
    Util.goRouteMap();
  },
};
</script>
<template>
  <div>
    vue3-setup-ts
  </div>
</template>
