import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import views from './modules/views';
import user from './modules/user';
import getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    views,
    user,
  },
  getters,
});
