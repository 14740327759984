/**
 * 功能函数
 *
 * 1.to(promise) {}
 *   promise 同步形式：
 *
 * 2.delParamsNull() {}
 *   删除对象空字段：
 *
 * 3.deepClone() {}
 *   深拷贝：
 *
 * 4.deepFreeze() {}
 *   深冻结
 *
 * 5.intersectionObject() {}
 *   对象取交集
 */
import Router from '@/router';

const util = {
  /**
   * async await捕获错误
   */
  to(promise) {
    return promise
      .then((res) => [null, res])
      .catch((err) => [err]);
  },
  /**
   * 删除空值字段
   */
  delParamsNull(data) {
    const params = data;
    if (params instanceof Object) {
      Object.keys(params).forEach((i) => {
        if (params[i] === '') {
          delete params[i];
        }
      });
    } else {
      console.log('传入格式错误');
    }
    return params;
  },
  // 深拷贝
  deepClone(data) {
    const type = this.getObjType(data);
    let obj;
    if (type === 'array') {
      obj = [];
    } else if (type === 'object') {
      obj = {};
    } else {
      return data;
    }
    if (type === 'array') {
      data.forEach((i) => {
        obj.push(this.deepClone(i));
      });
    } else if (type === 'object') {
      Object.keys(data).forEach((keyName) => {
        obj[keyName] = this.deepClone(data[keyName]);
      });
    }
    return obj;
  },
  /**
   * 变量类型判断
   */
  getObjType(obj) {
    const { toString } = Object.prototype;
    const map = {
      '[object Boolean]': 'boolean',
      '[object Number]': 'number',
      '[object String]': 'string',
      '[object Function]': 'function',
      '[object Array]': 'array',
      '[object Date]': 'date',
      '[object RegExp]': 'regExp',
      '[object Undefined]': 'undefined',
      '[object Null]': 'null',
      '[object Object]': 'object',
    };
    if (obj instanceof Element) {
      return 'element';
    }
    return map[toString.call(obj)];
  },
  /**
   * 深冻结
   */
  deepFreeze(obj) {
    const propNames = Object.getOwnPropertyNames(obj);
    propNames.forEach((name) => {
      const prop = obj[name];
      if (typeof prop === 'object' && prop !== null) this.deepFreeze(prop);
    });
    // 冻结自身
    return Object.freeze(obj);
  },
  /**
   * 对象取交集
   */
  intersectionObject(params1, params2) {
    const obj1 = params1;
    const obj2 = params2;
    Object.keys(obj1).forEach((objKey1) => {
      Object.keys(obj2).forEach((objKey2) => {
        if (objKey1 === objKey2) {
          obj1[objKey1] = obj2[objKey2];
        }
      });
    });
    return obj1;
  },
  judgeService() {
    const Adaptive = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|WebOS|Symbian|Windows Phone)/i;
    return Adaptive.exec(navigator.userAgent);
  },
  goRouteMap() {
    const isMobile = this.judgeService();
    if (isMobile) {
      Router.push('/mobile');
      return;
    }
    Router.push('/pc');
  },
};
export default util;
