import request from './_http';

const base = {
  // 上传客户提交的信息
  IndexMessages_addIndexMessages(data) {
    return request({
      url: '/base/IndexMessages/addIndexMessages',
      method: 'post',
      data,
    });
  },
};
export default base;
