var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogshow
    ? _c(
        "div",
        { ref: "dialogwrap", staticClass: "dialogcomp" },
        [
          _c("transition", { attrs: { name: "el-fade-in" } }, [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible"
                }
              ],
              staticClass: "shadow"
            })
          ]),
          _c("transition", { attrs: { name: "el-fade-in" } }, [
            _c("div", { staticClass: "dialogcontent" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.visible,
                      expression: "visible"
                    }
                  ],
                  ref: "dialogcontent",
                  staticClass: "dialog",
                  style: {
                    width: _vm.width,
                    marginTop: _vm.margintop + "px",
                    marginBottom: _vm.marginbottom + "px"
                  }
                },
                [
                  _c("p", { staticClass: "titlebar" }, [
                    _c("span", [_vm._v(_vm._s(_vm.owntitle || _vm.title))]),
                    _c("img", {
                      attrs: { src: require("./close.png") },
                      on: { click: _vm.handlecancel }
                    })
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "contentslot" },
                      [
                        _vm._t("default", [
                          _c("p", { staticClass: "msg" }, [
                            _vm._v(_vm._s(_vm.msg))
                          ])
                        ])
                      ],
                      2
                    ),
                    !_vm.hidebtns
                      ? _c("div", { staticClass: "btns" }, [
                          _vm.print
                            ? _c(
                                "button",
                                {
                                  staticClass: "printbtn",
                                  on: { click: _vm.handleprint }
                                },
                                [_vm._v("打印")]
                              )
                            : _vm._e(),
                          _c(
                            "button",
                            {
                              staticClass: "confirmbtn",
                              on: { click: _vm.handlesubmit }
                            },
                            [_vm._v(_vm._s(_vm.confirmtxt || "确认"))]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "cancelbtn",
                              on: { click: _vm.handlecancel }
                            },
                            [_vm._v(_vm._s(_vm.canceltxt || "取消"))]
                          )
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }