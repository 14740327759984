import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store/index';

import Api from './api/_index';
import Util from './util/_index';
import Const from './const/index';
import Filter from './filter/index';
import directives from './directives/_index';

import '@/components';
import '@/styles/_index.scss';

Vue.use(directives);
Vue.use(ElementUI);

/**
 * 全局挂载
 */
Vue.prototype.$api = Api;
Vue.prototype.$util = Util;
Vue.prototype.$const = Const;
Vue.prototype.$filter = Filter;

/**
 * vue 全局设置
 */
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
