import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../page/index/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/pc',
    name: 'Pc',
    component: () => import('../page/pc/index.vue'),
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('../page/mobile/index.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
