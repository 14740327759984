import util from './util';
import validate from './validate';
import storage from './storage';

const utils = {
  ...util,
  ...validate,
  ...storage,
};
export default utils;
