/**
 * 通用常量
 */
const viewsCommon = {
  // 金额格式
  moneyRegular: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}\.[0-9]{0,1}[1-9]{1}$)|(^(0){1}\.[1-9]{1}[0-9]{1}$)|(^(0){1}\.[1-9]{1}$)|(^0$)|(^(0){1}\.(0){1,2}$)/,
  // 手机格式
  phoneRegular: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
};
export default viewsCommon;
