import publicApi from './public-api';

import formatDate from './format/date';
import format from './format/format';

import mapColor from './map/color';
import mapStatus from './map/status';
import mapType from './map/type';

const filter = {
  ...publicApi,

  ...format,
  ...formatDate,

  ...mapColor,
  ...mapStatus,
  ...mapType,
};
export default filter;
