/**
 * 本地存储
 */
import validate from './validate';

const storage = {
  /**
   * 存储
   */
  setStorage(params) {
    const { name, content, type } = params;
    const obj = {
      dataType: typeof (content),
      content,
      type,
      datatime: new Date().getTime(),
    };
    if (type) {
      window.sessionStorage.setItem(name, JSON.stringify(obj));
    } else {
      window.localStorage.setItem(name, JSON.stringify(obj));
    }
  },
  /**
   * 获取
   */
  getStorage(params) {
    const { name } = params;
    let obj = {};
    obj = window.sessionStorage.getItem(name);
    if (validate.validateNull(obj)) { obj = window.localStorage.getItem(name); }
    if (validate.validateNull(obj)) { return undefined; }
    const { content } = JSON.parse(obj);
    return content;
  },
  /**
   * 删除
   */
  removeStore(params) {
    const {
      name,
    } = params;
    window.localStorage.removeItem(name);
    window.sessionStorage.removeItem(name);
  },
  /**
   * 清空
   */
  clearStore(params) {
    const {
      type,
    } = params;
    if (type) {
      window.sessionStorage.clear();
      return;
    }
    window.localStorage.clear();
  },
};
export default storage;
