/**
 * 全站网络配置
 */
import { Message } from 'element-ui';
import axios from 'axios';
import Qs from 'qs';

const baseURL = process.env.VUE_APP_URL;
// 设置axios默认配置
const service = axios.create({
  baseURL,
  timeout: 10000,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
});

service.interceptors.request.use(
  (config) => {
    if (config.data.formtype === 'json') {
      const { formdata } = config.data;
      const contentType = 'application/json;charset=UTF-8';
      config.data = formdata;
      config.headers = {
        'content-type': contentType,
      };
    }
    config.data = Qs.stringify(config.data);
    return config;
  },
  (error) => {
    console.log(error);
  },
);

service.interceptors.response.use(
  (response) => {
    const status = Number(response.status) || 200; // http状态码
    const { resultCode, result } = response.data; // 接口状态码和返回结果
    const message = response.data.message || '系统错误，请联系系统管理员';
    if (status === 200) { // http返回码处理
      if (resultCode === 1) { // 接口返回码处理
        if (result) { // 接口数据智能过滤
          return response.data.result;
        }
        return response.data;
      }
      Message({
        message,
        type: 'error',
      });
      return Promise.reject(message);
    }
    Message({
      message,
      type: 'error',
    });
    return Promise.reject(message);
  },
  (error) => {
    Message({
      message: '系统错误，请联系系统管理员！',
      type: 'error',
    });
    return Promise.reject(error);
  },
);
export default service;
