import debounce from './debounce';
import throttle from './throttle';
import imageError from './image-error';
import thumbnail from './thumbnail';

const directives = {
  debounce,
  throttle,
  imageError,
  thumbnail,
};

export default {
  install(Vue) {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key]);
    });
  },
};
